import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  Card,
  CardContent,
  Collapse,
  ButtonGroup,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

export default function Feedback() {
  const [feedbacks, setFeedbacks] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [openedFeedbackId, setOpenedFeedbackId] = useState(null);
  const [filterType, setFilterType] = useState("unread");

  const sampleFeedbacks = [
    {
      _id: 1,
      user: { name: "John Doe" },
      feedbackText: "Great service!",
      isRead: false,
      createdAt: "2024-10-01T10:30:00",
    },
    {
      _id: 2,
      user: { name: "Jane Smith" },
      feedbackText: "I had an issue with my order.",
      isRead: true,
      createdAt: "2024-10-02T09:15:00",
    },
    {
      _id: 3,
      user: { name: "Sam Wilson" },
      feedbackText: "Super fast delivery.",
      isRead: false,
      createdAt: "2024-10-03T12:45:00",
    },
    {
      _id: 4,
      user: { name: "Alex Johnson" },
      feedbackText: "Good product quality.",
      isRead: true,
      createdAt: "2024-09-30T14:00:00",
    },
  ];

  useEffect(() => {
    setFeedbacks(sampleFeedbacks);
  }, []);

  const filteredFeedbacks = feedbacks
    .filter((feedback) => {
      if (filterType === "unread") return !feedback.isRead;
      if (filterType === "read") return feedback.isRead;
      return true;
    })
    .filter((feedback) =>
      feedback.feedbackText.toLowerCase().includes(searchTerm.toLowerCase())
    );

  const handleFeedbackClick = (id) => {
    setOpenedFeedbackId(openedFeedbackId === id ? null : id);
  };

  const handleFilterChange = (type) => {
    setFilterType(type);
  };

  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        gap="15px"
        alignItems="center"
        sx={{ pr: 2, m: 2, mt: 0, maxWidth: "100vw" }}
      >
        <Grid item>
          <h1>Feedback</h1>
        </Grid>
        <Grid item>
          <TextField
            id="outlined-search"
            label="Search"
            type="search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{
              mt: 2,
              mb: 2,
              width: "220px",
              "& .MuiInputBase-root": { height: "40px" },
              "& .MuiInputLabel-root": { lineHeight: "13px" },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button variant="contained" sx={{ m: 2, mr: 0 }}>
            <FilterAltIcon />
          </Button>
        </Grid>
      </Grid>

      <Grid container justifyContent="center" sx={{ mb: 2 }}>
        <ButtonGroup variant="outlined">
          <Button
            onClick={() => handleFilterChange("all")}
            variant={filterType === "all" ? "contained" : "outlined"}
          >
            All
          </Button>
          <Button
            onClick={() => handleFilterChange("unread")}
            variant={filterType === "unread" ? "contained" : "outlined"}
          >
            Unread
          </Button>
          <Button
            onClick={() => handleFilterChange("read")}
            variant={filterType === "read" ? "contained" : "outlined"}
          >
            Read
          </Button>
        </ButtonGroup>
      </Grid>

      <Grid container spacing={2} sx={{ p: 2 }}>
        {filteredFeedbacks.length > 0 ? (
          filteredFeedbacks.map((feedback) => (
            <Grid item xs={12} md={6} lg={4} key={feedback._id}>
              <Card
                onClick={() => handleFeedbackClick(feedback._id)}
                sx={{ cursor: "pointer" }}
              >
                <CardContent>
                  <Typography variant="h6">
                    Feedback from {feedback.user?.name || "Unknown"}
                  </Typography>
                  <Typography variant="body2">
                    {feedback.feedbackText}
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    {new Date(feedback.createdAt).toLocaleString()}
                  </Typography>
                </CardContent>

                <Collapse
                  in={openedFeedbackId === feedback._id}
                  timeout="auto"
                  unmountOnExit
                >
                  <CardContent>
                    <Typography variant="body2" color="text.secondary">
                      More details about the feedback (e.g., additional
                      information, actions).
                    </Typography>
                  </CardContent>
                </Collapse>
              </Card>
            </Grid>
          ))
        ) : (
          <Typography variant="h6" sx={{ p: 2 }}>
            No feedbacks to display.
          </Typography>
        )}
      </Grid>
    </>
  );
}
