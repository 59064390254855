import React, { useEffect, useState } from "react";
import {
  TextField,
  InputAdornment,
  IconButton,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Modal,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Stack,
  Pagination,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import CloseIcon from "@mui/icons-material/Close";
import { networkRequest } from "../../utils/network_request";
import { apiAuth } from "../../utils/urls";
import { useLoading } from "../../utils/LoadingContext";
import { domain } from "../../utils/domain";
import { debounce } from "lodash";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  width: "auto",
  maxHeight: "80vh", // Set max height for the modal
  overflowY: "auto", // Enable vertical scroll
  transform: "translate(-50%, -50%)",
  p: 3,
  backgroundColor: "white",
  borderRadius: "8px",
};

function Posts() {
  const { setLoading } = useLoading();
  const [postDetails, setPostDetails] = useState({});
  const [open, setOpen] = useState(false);
  const [posts, setPosts] = useState([]);
  const [sortOrder, setSortOrder] = useState({ column: "", order: "" });
  const [state, setState] = useState("");
  const [district, setDistrict] = useState("");
  const [taluka, setTaluka] = useState("");
  const [village, setVillage] = useState("");
  const [like, setLike] = useState();
  const [status, setStatus] = useState("");
  const [sortedData, setSortedData] = useState(posts);
  const [expanded, setExpanded] = useState(false);
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [talukas, setTalukas] = useState([]);
  const [villages, setVillages] = useState([]);
  const [currentPage, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(10);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleOpen = (details) => {
    setPostDetails(details);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const truncateText = (text, wordLimit) => {
    if (!text) return "";
    const words = text.split(" ");
    return words.length > wordLimit
      ? words.slice(0, wordLimit).join(" ") + "..."
      : text;
  };

  const fetchPosts = async (
    search = "",
    location_name = "",
    location_id = ""
  ) => {
    try {
      let url = `${apiAuth.getPosts}?page=${currentPage}&like=true`;
      if (search) {
        url = `${apiAuth.getPosts}?search=${search}&like=true&page`;
      } else if (location_id && location_name) {
        url = `${apiAuth.getPosts}?location_name=${location_name}&location_id=${location_id}&like=true`;
      } else {
        url = `${apiAuth.getPosts}?page=${currentPage}&like=true`;
      }

      setPosts([]);
      setSortedData([]);
      await networkRequest(
        url,
        (t) => {
          setLoading(t);
        },
        (response) => {
          setPosts(response.data);
          setTotalPages(response.pagination.total_pages);
          setSortedData(response.data);
        }
      );
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    fetchPosts();
  }, [currentPage]);

  useEffect(() => {
    sortPosts();
  }, [like]);

  useEffect(() => {
    fetchStates();
  }, []);
  const handleSearch = debounce((event) => {
    const searchTerm = event.target.value;
    fetchPosts(searchTerm); // Fetch users with search query
  }, 500);

  const sortPosts = () => {
    let sortedPosts = [...posts];

    if (like === 10) {
      sortedPosts.sort((a, b) => b.like - a.like);
      console.log(sortedPosts);
    } else if (like === 20) {
      sortedPosts.sort((a, b) => b.dislike - a.dislike);
    } else if (like === 30) {
      sortedPosts.sort((a, b) => b.views - a.views);
    }

    setSortedData(sortedPosts);
  };
  const fetchStates = () => {
    networkRequest(
      apiAuth.getStates,
      () => {},
      (res) => {
        setStates(res.data);
      }
    );
  };
  const fetchDistrict = (id) => {
    networkRequest(
      `${apiAuth.getDistricts}/${id}`,
      () => {},
      (res) => {
        setDistricts(res.data);
      }
    );
  };
  const fetchTaluka = (id) => {
    networkRequest(
      `${apiAuth.getTalukas}/${id}`,
      () => {},
      (res) => {
        setTalukas(res.data);
      }
    );
  };

  const fetchVillege = (id) => {
    networkRequest(
      `${apiAuth.getVillages}/${id}`,
      () => {},
      (res) => {
        setVillages(res.data);
      }
    );
  };

  const handleChange = (event) => {
    const selectedState = event.target.value;
    setState(selectedState);
    fetchDistrict(selectedState.id);
    fetchPosts("", "state", selectedState.id);
  };
  const handleChangeDis = (event) => {
    const selectedDistrict = event.target.value;
    setDistrict(selectedDistrict);
    fetchTaluka(selectedDistrict.id);
    fetchPosts("", "district", selectedDistrict.id);
  };
  const handleChangeTaluka = (event) => {
    const selectedTaluka = event.target.value;
    setTaluka(selectedTaluka);
    fetchVillege(selectedTaluka.id);
    fetchPosts("", "taluka", selectedTaluka.id);
  };
  const handleChangeVillage = (event) => {
    const selectedVillage = event.target.value;
    setVillage(selectedVillage);
    fetchPosts("", "village", selectedVillage.id);
  };
  const handleChangeLike = (event) => setLike(event.target.value);
  const handleChangeStatus = (event) => setStatus(event.target.value);

  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        gap="15px"
        alignItems="center"
        sx={{ pt: 2, pr: 2, m: 2, maxWidth: "100vw" }}
      >
        <Grid item>
          <h1>Posts</h1>
        </Grid>

        <Grid item>
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">State</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={state}
                label="Age"
                onChange={handleChange}
              >
                {states.map((state) => (
                  <MenuItem key={state} value={state}>
                    {state.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item>
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">District</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={district}
                label="Age"
                onChange={handleChangeDis}
              >
                {districts.map((district) => (
                  <MenuItem key={district.id} value={district}>
                    {district.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item>
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="taluka-select-label">Taluka</InputLabel>
              <Select
                labelId="taluka-select-label"
                id="taluka-select"
                value={taluka}
                label="Taluka"
                onChange={handleChangeTaluka}
              >
                {talukas.map((taluka) => (
                  <MenuItem key={taluka.id} value={taluka}>
                    {taluka.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item>
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="village-select-label">Village</InputLabel>
              <Select
                labelId="village-select-label"
                id="village-select"
                value={village}
                label="Village"
                onChange={handleChangeVillage}
              >
                {villages.map((village) => (
                  <MenuItem key={village.id} value={village}>
                    {village.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item>
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Like</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={like}
                label="Age"
                onChange={handleChangeLike}
              >
                <MenuItem value={10}>Most Liked</MenuItem>
                <MenuItem value={20}>Most Disliked</MenuItem>
                <MenuItem value={30}>Most View</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item>
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Status</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={status}
                label="Age"
                onChange={handleChangeStatus}
              >
                <MenuItem value={10}>Block</MenuItem>
                <MenuItem value={20}>Unblock</MenuItem>
                <MenuItem value={30}>Blank</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>
        {/* Additional Filters */}
        <Grid item>
          <TextField
            id="outlined-search"
            label="Search"
            type="search"
            onChange={handleSearch}
            sx={{
              mt: 2,
              mb: 2,
              width: "220px",
              "& .MuiInputBase-root": { height: "40px" },
              "& .MuiInputLabel-root": { lineHeight: "13px" },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button variant="contained" sx={{ m: 2, mr: 0 }}>
            <FilterAltIcon />
          </Button>
        </Grid>
      </Grid>
      <TableContainer
        component={Paper}
        sx={{ maxWidth: "100vw", overflow: "auto" }}
      >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center" sx={{ py: 2 }}>
                Sr. No
              </TableCell>
              <TableCell align="center" sx={{ py: 2 }}>
                TITLE
              </TableCell>
              <TableCell align="center" sx={{ py: 2 }}>
                DESCRIPTION
              </TableCell>
              <TableCell align="center">LIKES</TableCell>
              <TableCell align="center" sx={{ py: 2 }}>
                DISLIKES
              </TableCell>
              <TableCell align="center">VIEWS</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData.length > 0 ? (
              sortedData.map((row) => (
                <TableRow
                  key={row.id}
                  onClick={() => {
                    const imageArray = [];
                    if (row.picture1) imageArray.push(row.picture1);
                    if (row.picture2) imageArray.push(row.picture2);
                    if (row.picture3) imageArray.push(row.picture3);
                    handleOpen({
                      title: row.title,
                      description: row.description,
                      like: row.like,
                      dislike: row.dislike,
                      views: row.views,
                      images: imageArray,
                    });
                  }}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    height: "60px",
                    cursor: "pointer",
                  }}
                >
                  <TableCell
                    align="center"
                    component="th"
                    scope="row"
                    sx={{ py: 1 }}
                  >
                    {row.id}
                  </TableCell>
                  <TableCell align="center" sx={{ py: 2 }}>
                    {truncateText(row.title, 3) || "N/A"}
                  </TableCell>
                  <TableCell align="center" sx={{ py: 2 }}>
                    {truncateText(row.description, 4) || "N/A"}
                  </TableCell>
                  <TableCell align="center" sx={{ py: 2 }}>
                    {row.like}
                  </TableCell>
                  <TableCell align="center" sx={{ py: 2 }}>
                    {row.dislike}
                  </TableCell>
                  <TableCell align="center" sx={{ py: 2 }}>
                    {row.views}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  No Posts found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Stack spacing={2}>
        <Pagination
          count={totalPages}
          page={currentPage} // Controlled component
          onChange={handlePageChange}
          color="primary"
        />
      </Stack>

      {/* Modal for displaying images and post details */}
      <Modal open={open} onClose={handleClose}>
        <Box sx={modalStyle}>
          <IconButton
            onClick={handleClose}
            sx={{ position: "absolute", right: 10, top: 10 }}
          >
            <CloseIcon />
          </IconButton>
          <h2>{postDetails.title}</h2>
          <Grid container spacing={2}>
            {postDetails.images?.map((img, index) => (
              <Grid item xs={12} sm={4} key={index}>
                <img
                  src={`${domain}/${img}`}
                  alt={`Post Image ${index + 1}`}
                  style={{ width: "100%", height: "auto", borderRadius: "8px" }}
                />
              </Grid>
            ))}
          </Grid>
          <p>
            <strong>Likes:</strong> {postDetails.like} &nbsp;&nbsp;&nbsp;
            <strong>Dislikes:</strong> {postDetails.dislike}&nbsp;&nbsp;&nbsp;
            <strong>Views:</strong> {postDetails.views}
          </p>
          <p>
            {expanded
              ? postDetails.description
              : truncateText(postDetails.description || "", 25)}
            {postDetails.description &&
              postDetails.description.split(" ").length > 25 && (
                <span
                  onClick={() => setExpanded(!expanded)}
                  style={{
                    color: "blue",
                    cursor: "pointer",
                    marginLeft: "5px",
                  }}
                >
                  {expanded ? " Less" : " Read More"}
                </span>
              )}
          </p>
        </Box>
      </Modal>
    </>
  );
}

export default Posts;
