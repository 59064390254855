import { domain } from "./domain";

const apiGeneral = {
  login: `${domain}/api/v1/admin/login`,
};

//api which required authentication
const apiAuth = {
  getPlans: `${domain}/api/v1/admin/plan/get`,
  deletePlan: `${domain}/api/v1/admin/plan/delete`,
  createPlan: `${domain}/api/v1/admin/plan/create`,
  dashboardData: `${domain}/api/v1/admin/dashboard`,
  getUsers: `${domain}/api/v1/admin/users`,
  getPosts: `${domain}/api/v1/admin/posts`,

  getStates: `${domain}/api/v1/states`,
  getDistricts: `${domain}/api/v1/districts`,
  getTalukas: `${domain}/api/v1/talukas`,
  getVillages: `${domain}/api/v1/villages`,
};

export { apiAuth, apiGeneral };
