import React, { useEffect, useState } from "react";
import {
  TextField,
  InputAdornment,
  IconButton,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Avatar,
  Box,
  Modal,
  Stack,
  Pagination,
  ButtonGroup,
  Switch,
  Checkbox,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { networkRequest } from "../../utils/network_request";
import { apiAuth } from "../../utils/urls";
import { useLoading } from "../../utils/LoadingContext";
import { debounce } from "lodash";
import { domain } from "../../utils/domain";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  width: "auto",
  height: "auto",
  transform: "translate(-50%, -50%)",
  p: 3,
  backgroundColor: "white",
};

export default function Users() {
  const { setLoading } = useLoading();
  const [users, setUsers] = useState([]);
  const [image, setImage] = useState();
  const [open, setOpen] = useState(false);
  const [currentPage, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(10);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleOpen = (photo) => {
    setImage(photo);
    console.log(photo);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const fetchUsers = async (search = "") => {
    try {
      const url = search
        ? `${apiAuth.getUsers}?search=${search}&page1`
        : `${apiAuth.getUsers}?page=${currentPage}&search`;

      await networkRequest(
        url,
        (t) => {
          setLoading(t);
        },
        (response) => {
          setUsers(response.data);
          setTotalPages(response.pagination.total_pages);
        }
      );
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [currentPage]);

  const handleSearch = debounce((event) => {
    const searchTerm = event.target.value;
    fetchUsers(searchTerm);
  }, 500);

  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        gap="15px"
        alignItems="center"
        sx={{ pr: 2, m: 2, mt: 0, maxWidth: "100vw" }}
      >
        <Grid item>
          <h1>Users</h1>
        </Grid>
        <Grid item>
          <TextField
            id="outlined-search"
            label="Search"
            type="search"
            onChange={handleSearch}
            sx={{
              width: "200px",
              mt: 2,
              mb: 2,
              "& .MuiInputBase-root": {
                height: "40px",
              },
              "& .MuiInputLabel-root": {
                lineHeight: "13px",
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <Tooltip title="Filter">
            <Button variant="contained" sx={{ m: 2, mr: 0 }}>
              <FilterAltIcon />
            </Button>
          </Tooltip>
        </Grid>
      </Grid>
      <TableContainer
        component={Paper}
        sx={{ maxWidth: "100vw", overflow: "auto" }}
      >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ py: 2 }}>PROFILE</TableCell>
              <TableCell align="center" sx={{ py: 2 }}>
                USERNAME
              </TableCell>
              <TableCell align="center" sx={{ py: 2 }}>
                MOBILE NO
              </TableCell>
              <TableCell align="center" sx={{ py: 2 }}>
                EMAIL
              </TableCell>
              <TableCell align="center" sx={{ py: 2 }}>
                ADDRESS
              </TableCell>
              <TableCell align="center" sx={{ py: 2 }}>
                ORGANIZATION
              </TableCell>
              <TableCell align="center" sx={{ py: 2 }}>
                DESIGNATION
              </TableCell>
              <TableCell align="center" sx={{ py: 2 }}>
                VILLAGE ID
              </TableCell>
              <TableCell align="center" sx={{ py: 2 }}>
                ACTION
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.length > 0 ? (
              users.map((user) => (
                <TableRow
                  key={user.id}
                  onClick={() =>
                    handleOpen(
                      `${domain}/${user.profile_pic}` ||
                        "https://via.placeholder.com/150"
                    )
                  }
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    height: "60px",
                    cursor: "pointer",
                  }}
                >
                  <TableCell sx={{ py: 2 }}>
                    <Avatar
                      src={
                        `${domain}/${user.profile_pic}` ||
                        "https://via.placeholder.com/150"
                      }
                    />
                  </TableCell>
                  <TableCell align="center" sx={{ py: 2 }}>
                    {user.name}
                  </TableCell>
                  <TableCell align="center" sx={{ py: 2 }}>
                    {user.contact}
                  </TableCell>
                  <TableCell align="center" sx={{ py: 2 }}>
                    {user.email || "N/A"}
                  </TableCell>
                  <TableCell align="center" sx={{ py: 2 }}>
                    {user.address || "N/A"}
                  </TableCell>
                  <TableCell align="center" sx={{ py: 2 }}>
                    {user.organization || "N/A"}
                  </TableCell>
                  <TableCell align="center" sx={{ py: 2 }}>
                    {user.designation || "N/A"}
                  </TableCell>
                  <TableCell align="center" sx={{ py: 2 }}>
                    {user.village_id}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ py: 2 }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <ButtonGroup
                      variant="contained"
                      aria-label="Basic button group"
                      sx={{ float: "flex", m: 2 }}
                    >
                      <Tooltip title="Block / Unblock">
                        <Switch />
                      </Tooltip>

                      <Tooltip title="Promote">
                        <Checkbox />
                      </Tooltip>
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  No users found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Stack spacing={2} sx={{ alignItems: "center", mt: 2 }}>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
        />
      </Stack>

      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              p: 0,
            }}
          >
            <h5 style={{ margin: 0, marginBottom: "30px", padding: 0 }}>
              Profile Picture
            </h5>
            <CloseIcon onClick={handleClose} />
          </Box>
          <img
            src={image}
            alt="User Profile"
            style={{
              maxWidth: "100%",
              maxHeight: "400px",
              objectFit: "contain",
            }}
          />
        </Box>
      </Modal>
    </>
  );
}
